export const sizeChart = [
  { us: 3, diameter: 14.1, uk: 'F', france: '44', germany: '44', italy: '4', spain: '4', japan: '4', circumference: 44.2 },
  { us: 3.5, diameter: 14.5, uk: 'G', france: '46', germany: '46', italy: '5', spain: '5', japan: '5', circumference: 45.5 },
  { us: 4, diameter: 14.9, uk: 'H', france: '47', germany: '47', italy: '7', spain: '7', japan: '7', circumference: 46.8 },
  { us: 4.5, diameter: 15.3, uk: 'I', france: '48', germany: '48', italy: '8', spain: '8', japan: '8', circumference: 48 },
  { us: 5, diameter: 15.7, uk: 'J-½', france: '50', germany: '50', italy: '10', spain: '9.5', japan: '9', circumference: 49.3 },
  { us: 5.5, diameter: 16.1, uk: 'K-½', france: '51', germany: '51', italy: '11', spain: '10.5', japan: '10', circumference: 50.6 },
  { us: 6, diameter: 16.5, uk: 'L-½', france: '52', germany: '52', italy: '12', spain: '12', japan: '11', circumference: 51.9 },
  { us: 6.5, diameter: 16.9, uk: 'M-½', france: '53', germany: '53', italy: '13', spain: '13', japan: '13', circumference: 53.1 },
  { us: 7, diameter: 17.3, uk: 'N-½', france: '54', germany: '54', italy: '15', spain: '14.5', japan: '14', circumference: 54.4 },
  { us: 7.5, diameter: 17.7, uk: 'O-½', france: '56', germany: '56', italy: '16', spain: '16', japan: '15', circumference: 55.7 },
  { us: 8, diameter: 18.1, uk: 'P-½', france: '57', germany: '57', italy: '17', spain: '17', japan: '16', circumference: 57 },
  { us: 8.5, diameter: 18.5, uk: 'Q-½', france: '58', germany: '58', italy: '18', spain: '18.5', japan: '17', circumference: 58.3 },
  { us: 9, diameter: 19, uk: 'R-½', france: '59', germany: '59', italy: '20', spain: '19.5', japan: '18', circumference: 59.5 },
  { us: 9.5, diameter: 19.4, uk: 'S-½', france: '61', germany: '61', italy: '21', spain: '21', japan: '20', circumference: 60.8 },
  { us: 10, diameter: 19.8, uk: 'T-½', france: '62', germany: '62', italy: '22', spain: '22', japan: '21', circumference: 62.1 },
  { us: 10.5, diameter: 20.2, uk: 'U-½', france: '63', germany: '63', italy: '24', spain: '23.5', japan: '22', circumference: 63.4 },
  { us: 11, diameter: 20.6, uk: 'V-½', france: '64', germany: '64', italy: '25', spain: '24.5', japan: '23', circumference: 64.6 },
  { us: 11.5, diameter: 21, uk: 'W-½', france: '65', germany: '65', italy: '26', spain: '26', japan: '25', circumference: 65.9 },
  { us: 12, diameter: 21.4, uk: 'X-½', france: '67', germany: '67', italy: '27', spain: '27.5', japan: '26', circumference: 67.2 },
  { us: 12.5, diameter: 21.8, uk: 'Z', france: '68', germany: '68', italy: '28', spain: '28.5', japan: '28', circumference: 68.5 },
  { us: 13, diameter: 22.2, uk: 'Z+1', france: '70', germany: '70', italy: '30', spain: '30', japan: '29', circumference: 69.7 },
  { us: 13.5, diameter: 22.6, uk: 'Z+1.5', france: '71', germany: '71', italy: '31', spain: '31', japan: '30', circumference: 71 },
  { us: 14, diameter: 23, uk: 'Z+3', france: '72', germany: '72', italy: '32', spain: '32.5', japan: '31', circumference: 72.3 },
  { us: 14.5, diameter: 23.4, uk: 'Z+4', france: '73', germany: '73', italy: '33', spain: '33.5', japan: '32', circumference: 73.5 },
  { us: 15, diameter: 23.8, uk: 'Z+5', france: '74', germany: '74', italy: '35', spain: '35', japan: '33', circumference: 74.8 }
];