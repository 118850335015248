<template>
  <footer class="footer bg-light text-center py-4">
    <div class="container">
      <p class="mb-2">
        &copy; 2024 RingSize.NET - Easy & Free Ring Size Calculator
      </p>
      
      <div class="footer-links">
        <a href="/about-us" class="footer-link">About Us</a> |
        <a href="/contact-us" class="footer-link">Contact Us</a> |
        <a href="/terms-conditions" class="footer-link">Terms & Conditions</a> |
        <a href="/privacy-policy" class="footer-link">Privacy Policy</a> |
        <a href="/faq" class="footer-link">FAQ</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 20px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #0056b3;
}

.footer-links {
  margin-top: 10px;
}

.footer-link {
  font-size: 14px;
  padding: 0 8px;
}

.footer p {
  font-size: 16px;
  margin-bottom: 10px;
}
</style>