import { createRouter, createWebHistory } from 'vue-router';
import { useHead } from '@vueuse/head';
import AppHome from '../components/AppHome.vue';
import StringMethod from '../components/StringMethod.vue';
import RingMethod from '../components/RingMethod.vue';
import CameraMethod from '../components/CameraMethod.vue';
import SizeConverter from '../components/SizeConverter.vue';
import SizeChart from '../components/SizeChart.vue';
import AboutUs from '@/components/AboutUs.vue';
import ContactUs from '@/components/ContactUs.vue';
import TermsConditions from '@/components/TermsConditions.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import FAQ from '@/components/FAQ.vue';
import RingCircles from '@/components/RingCircles.vue';
import PaperRingSizer from '@/components/PaperRingSizer.vue';
import NotFound from '../components/NotFound.vue';

// Define the routes
const routes = [
  // Home route
  {
    path:        '/',
    name:        'AppHome',
    component:   AppHome,
    meta: {
      title:       'Ring Size Calculator - RingSize.NET',
      description: 'Find your perfect ring size with our easy-to-use ring size calculator. Get accurate measurements using different methods like string, ring, and size chart.',
      ogTitle:     'Ring Size Calculator - RingSize.NET',
      ogDescription: 'Find your perfect ring size with our easy-to-use ring size calculator. Get accurate measurements using different methods like string, ring, and size chart.'
    }
  },
  {
    path:        '/string-method',
    name:        'StringMethod',
    component:   StringMethod,
    meta: {
      title:       'String Method - RingSize.NET',
      description: 'Use the string method to measure your ring size accurately at home. Follow our step-by-step instructions for perfect results.',
      ogTitle:     'String Method - RingSize.NET',
      ogDescription: 'Use the string method to measure your ring size accurately at home. Follow our step-by-step instructions for perfect results.'
    }
  },
  {
    path:        '/ring-method',
    name:        'RingMethod',
    component:   RingMethod,
    meta: {
      title:       'Ring Method - RingSize.NET',
      description: 'Use your existing ring to measure your perfect size. Learn how to use the ring method for accurate results in finding your ring size.',
      ogTitle:     'Ring Method - RingSize.NET',
      ogDescription: 'Use your existing ring to measure your perfect size. Learn how to use the ring method for accurate results in finding your ring size.'
    }
  },
  {
    path:        '/camera-method',
    name:        'CameraMethod',
    component:   CameraMethod,
    meta: {
      title:       'Camera Method - RingSize.NET',
      description: 'Use your camera to measure your perfect ring size by aligning your finger with a credit card. Accurate and easy measurement technique.',
      ogTitle:     'Camera Method - RingSize.NET',
      ogDescription: 'Use your camera to measure your perfect ring size by aligning your finger with a credit card. Accurate and easy measurement technique.'
    }
  },
  {
    path:        '/size-converter',
    name:        'SizeConverter',
    component:   SizeConverter,
    meta: {
      title:       'Ring Size Converter - RingSize.NET',
      description: 'Convert your ring size from one country’s system to another. RingSize.NET supports multiple international ring size systems.',
      ogTitle:     'Ring Size Converter - RingSize.NET',
      ogDescription: 'Convert your ring size from one country’s system to another. RingSize.NET supports multiple international ring size systems.'
    }
  },
  {
    path:        '/size-chart',
    name:        'SizeChart',
    component:   SizeChart,
    meta: {
      title:       'Ring Size Chart - RingSize.NET',
      description: 'Explore our detailed ring size chart to help you find the perfect fit. We provide sizes from all major international systems.',
      ogTitle:     'Ring Size Chart - RingSize.NET',
      ogDescription: 'Explore our detailed ring size chart to help you find the perfect fit. We provide sizes from all major international systems.'
    }
  },
  {
    path:        '/ring-circles',
    name:        'RingCircles',
    component:   RingCircles,
    meta: {
      title:       'Ring Circles Method - RingSize.NET',
      description: 'Use our interactive ring circles tool to measure your ring size directly on screen. Adjust the outline to find the right fit.',
      ogTitle:     'Ring Circles Method - RingSize.NET',
      ogDescription: 'Use our interactive ring circles tool to measure your ring size directly on screen. Adjust the outline to find the right fit.'
    }
  },
  {
    path:        '/paper-ring-sizer',
    name:        'PaperRingSizer',
    component:   PaperRingSizer,
    meta: {
      title:       'Paper Ring Sizer - RingSize.NET',
      description: 'Measure your finger using a paper ring sizer. Follow our easy instructions to determine your perfect ring size at home.',
      ogTitle:     'Paper Ring Sizer - RingSize.NET',
      ogDescription: 'Measure your finger using a paper ring sizer. Follow our easy instructions to determine your perfect ring size at home.'
    }
  },
  {
    path:        '/about-us',
    name:        'AboutUs',
    component:   AboutUs,
    meta: {
      title:       'About Us - RingSize.NET',
      description: 'Learn more about RingSize.NET and our mission to help people find their perfect ring size with accuracy and ease.',
      ogTitle:     'About Us - RingSize.NET',
      ogDescription: 'Learn more about RingSize.NET and our mission to help people find their perfect ring size with accuracy and ease.'
    }
  },
  {
    path:        '/contact-us',
    name:        'ContactUs',
    component:   ContactUs,
    meta: {
      title:       'Contact Us - RingSize.NET',
      description: 'Have a question or need help with our ring sizing tools? Contact us and our support team will assist you promptly.',
      ogTitle:     'Contact Us - RingSize.NET',
      ogDescription: 'Have a question or need help with our ring sizing tools? Contact us and our support team will assist you promptly.'
    }
  },
  {
    path:        '/terms-conditions',
    name:        'TermsConditions',
    component:   TermsConditions,
    meta: {
      title:       'Terms and Conditions - RingSize.NET',
      description: 'Review the terms and conditions for using RingSize.NET’s ring size tools and services. Understand our policies on usage and privacy.',
      ogTitle:     'Terms and Conditions - RingSize.NET',
      ogDescription: 'Review the terms and conditions for using RingSize.NET’s ring size tools and services. Understand our policies on usage and privacy.'
    }
  },
  {
    path:        '/privacy-policy',
    name:        'PrivacyPolicy',
    component:   PrivacyPolicy,
    meta: {
      title:       'Privacy Policy - RingSize.NET',
      description: 'Learn how RingSize.NET protects your privacy and handles your personal information. Our privacy policy outlines how we safeguard your data.',
      ogTitle:     'Privacy Policy - RingSize.NET',
      ogDescription: 'Learn how RingSize.NET protects your privacy and handles your personal information. Our privacy policy outlines how we safeguard your data.'
    }
  },
  {
    path:        '/faq',
    name:        'FAQ',
    component:   FAQ,
    meta: {
      title:       'FAQ - RingSize.NET',
      description: 'Find answers to common questions about ring sizing and how to use our tools. Our FAQ section provides helpful guidance.',
      ogTitle:     'FAQ - RingSize.NET',
      ogDescription: 'Find answers to common questions about ring sizing and how to use our tools. Our FAQ section provides helpful guidance.'
    }
  },
  // Catch-all route (404 page)
  {
    path:        '/:pathMatch(.*)*',
    name:        'NotFound',
    component:   NotFound,
    meta: {
      title:       'Page Not Found - RingSize.NET',
      description: 'Sorry, the page you are looking for does not exist. Please check the URL or return to the homepage.',
      ogTitle:     'Page Not Found - RingSize.NET',
      ogDescription: 'Sorry, the page you are looking for does not exist. Please check the URL or return to the homepage.'
    }
  }
];

// Create the router instance with createRouter
const router = createRouter({
  history: createWebHistory(), // Use the web history mode
  routes, // Set up the routes
});

// Navigation guard to update meta tags dynamically
router.beforeEach((to, from, next) => {
  // Extract meta from route
  const { title, description, ogTitle, ogDescription } = to.meta;

  // Set the page title
  if (title) {
    document.title = title;
  }

  // Use `useHead` to set the meta tags dynamically
  useHead({
    title,
    meta: [
      { name: 'description', content: description || '' },
      { property: 'og:title', content: ogTitle || '' },
      { property: 'og:description', content: ogDescription || '' }
    ]
  });

  next();
});

export default router;