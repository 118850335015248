<template>
  <div class="container-fluid py-4">
    <div class="row mb-4">
      <div class="col">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h1>International Ring Size Chart</h1>
          <button 
            class="btn btn-primary" 
            @click="exportToPdf"
          >
            <i class="fas fa-file-pdf me-2"></i>
            Download PDF
          </button>
        </div>
        
        <!-- Table Section -->
        <div class="table-responsive" ref="tableContainer">
          <table class="table table-bordered table-hover pdf-table">
            <thead class="table-light">
              <tr>
                <th 
                  v-for="header in headers" 
                  :key="header.key"
                  @click="sortBy(header.key)"
                  class="table-header"
                >
                  {{ header.label }}
                  <span 
                    v-if="sortKey === header.key" 
                    class="sort-icon"
                    :class="{ 'asc': sortOrder === 'asc', 'desc': sortOrder === 'desc' }"
                  ></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in sortedData" :key="row.diameter">
                <td>{{ row.diameter }}</td>
                <td>{{ row.circumference }}</td>
                <td>{{ row.us }}</td>
                <td>{{ row.uk }}</td>
                <td>{{ row.france }}</td>
                <td>{{ row.italy }}</td>
                <td>{{ row.spain }}</td>
                <td>{{ row.japan }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import { sizeChart } from '@/constants/sizeChart';

export default {
  name: 'SizeChart',
  data() {
    return {
      ringData: sizeChart,
      sortKey: '',
      sortOrder: 'asc',
      headers: [
        { key: 'diameter', label: 'Inside Diameter (MM)' },
        { key: 'circumference', label: 'Inside Circumference (MM)' },
        { key: 'us', label: 'US / Canada' },
        { key: 'uk', label: 'UK / Australia / South Africa' },
        { key: 'france', label: 'France / Germany' },
        { key: 'italy', label: 'Italy' },
        { key: 'spain', label: 'Spain' },
        { key: 'japan', label: 'Japan' }
      ]
    }
  },
  computed: {
    sortedData() {
      let data = [...this.ringData];
      if (this.sortKey) {
        const key = this.sortKey;
        const order = this.sortOrder;
        
        data.sort((a, b) => {
          let valueA = isNaN(a[key]) ? a[key] : parseFloat(a[key]);
          let valueB = isNaN(b[key]) ? b[key] : parseFloat(b[key]);
          
          if (valueA < valueB) return order === 'asc' ? -1 : 1;
          if (valueA > valueB) return order === 'asc' ? 1 : -1;
          return 0;
        });
      }
      return data;
    }
  },
  methods: {
    exportToPdf() {
      this.$gtag.event('download', {
        event_category: 'pdf',
        event_label: 'ring size chart',
        value: 1
      });
      const element = this.$refs.tableContainer;

      // Add PDF-specific styles before generating
      const style = document.createElement('style');
      style.textContent = `
        .pdf-table {
          border-collapse: collapse;
          width: 100%;
          font-size: 12px; /* Increase font size for better readability */
        }
        .pdf-table th,
        .pdf-table td {
          border: 1px solid #000;
          padding: 6px; /* Adequate padding for readability */
          text-align: left;
        }
        .pdf-table thead th {
          background-color: #f8f9fa;
          font-weight: bold;
        }
      `;
      element.appendChild(style);

      const opt = {
        margin: [0.25, 0.25], // Smaller margins for better content space
        filename: 'ring-size-chart.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { 
          scale: 1.5, // Adequate scale for crispness
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { 
          unit: 'in', 
          format: 'letter', // Standard letter size
          orientation: 'landscape' // Landscape orientation for width
        },
      };

      // Generate PDF
      html2pdf().set(opt).from(element).toPdf().get('pdf').then((pdf) => {
        // Add footer text
        const pageCount = pdf.internal.getNumberOfPages();
        const footerText = "www.RingSize.net";
        const fontSize = 10;

        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          
          pdf.setFontSize(fontSize);
          pdf.text(
            footerText,
            pageWidth / 2, // Center horizontally
            pageHeight - 0.5, // Place at bottom
            { align: 'center' }
          );
        }
      }).save().then(() => {
        // Remove the temporary style element after PDF generation
        element.removeChild(style);
      });
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
    }
  }
}
</script>

<style scoped>
.table-header {
  cursor: pointer;
  user-select: none;
}

.table-header:hover {
  background-color: #f8f9fa;
}

.sort-icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5em;
  vertical-align: middle;
}

.sort-icon.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid currentColor;
}

.sort-icon.desc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid currentColor;
}

.btn-primary {
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Add specific styles for the table in both screen and print */
.table {
  border-collapse: collapse !important;
}

.table th,
.table td {
  border: 1px solid #dee2e6 !important;
  padding: 8px;
}

/* PDF-specific styles */
:deep(.pdf-table) {
  border-collapse: collapse !important;
  font-size: 12px; /* Increased font size */
}

:deep(.pdf-table th),
:deep(.pdf-table td) {
  border: 1px solid #000 !important;
  padding: 4px !important; /* Better padding for readability */
}

:deep(.pdf-table thead th) {
  background-color: #f8f9fa !important;
  font-weight: bold !important;
}
</style>