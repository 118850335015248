<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <h1 class="text-center mb-4">Contact Us</h1>
        <div class="card shadow-sm">
          <div class="card-body">
            <p class="lead">
              If you have any questions or inquiries, feel free to contact us via email at 
              <a :href="emailLink">contact@ringsize.net</a>.
            </p>
            <p class="mb-0">We look forward to hearing from you!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// Obfuscated email
const email = 'contact' + '@' + 'ringsize.net'

// Create the mailto link dynamically to avoid bots scraping it
const emailLink = computed(() => `mailto:${email}`)
</script>