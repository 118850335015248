<template>
  <div class="container mt-4">
    <h2>Measure Your Ring Size with Paper</h2>
    <p>Follow the instructions below to determine your ring size using paper.</p>

    <!-- Instructions -->
    <div class="card p-4">
      <h5>Instructions</h5>
      <ol>
        <li>Cut out the ring sizer along the edges.</li>
        <li>Wrap the paper ring sizer snugly around the base of the intended finger so that the numbers are visible.</li>
        <li>Insert the pointed end through the slit, making sure the sizer fits tightly around the finger.</li>
        <li>Note the number that lines up with the slit; this is your ring size! If the sizer falls between two sizes, round up to the next quarter size for a better fit.</li>
        <li>For extra comfort, consider choosing the larger size if your measurement is in between two sizes.</li>
      </ol>
    </div>

    <!-- Button to generate the paper ring sizer PDF -->
    <div class="mt-3 text-center">
      <button @click="generateRingSizerPdf" class="btn btn-primary">Download Paper Ring Sizer PDF</button>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import { sizeChart } from '@/constants/sizeChart';

export default {
  name: 'PaperRingSizer',
  methods: {
    generateRingSizerPdf() {
      this.$gtag.event('download', {
        event_category: 'pdf',
        event_label: 'paper ring sizer',
        value: 1
      });
      const doc = new jsPDF();

      // Title and instructions
      doc.setFontSize(18);
      doc.text("Measure Your Ring Size with Paper", 105, 20, null, null, 'center');
      doc.setFontSize(12);
      doc.text("Follow the instructions below to determine your ring size using paper:", 105, 30, null, null, 'center');

      // Instruction list with more detailed instructions
      doc.text("1. Cut out the ring sizer along the edges.", 10, 50);
      doc.text("2. Wrap the paper ring sizer snugly around the base of the intended finger.", 10, 60);
      doc.text("3. Insert the pointed end through the slit and note the number that lines up with the slit.", 10, 70);
      doc.text("4. If you find that your sizer is between two sizes, round up to the next quarter size for a better fit.", 10, 80);
      doc.text("5. For extra comfort, consider choosing the larger size if your measurement is between two sizes.", 10, 90);

      // Draw the paper ring sizer ruler with enhanced accuracy
      const rulerStartX = 20; 
      const rulerStartY = 120;
      const rulerWidth = 180; 
      const rulerHeight = 25; 

      // Draw the ruler background
      doc.setFillColor(240, 240, 240); 
      doc.rect(rulerStartX, rulerStartY, rulerWidth, rulerHeight, 'F');

      // Draw the size markings based on the sizeChart
      let currentX = rulerStartX + 10;
      const tickHeight = 10; 
      const textOffset = 2;
      const spacing = (rulerWidth - 20) / (sizeChart.length - 1); 

      // Loop through sizeChart and draw the markings
      for (let i = 0; i < sizeChart.length; i++) {
        const size = sizeChart[i];

        // Draw the size tick mark
        doc.setLineWidth(0.8);
        doc.line(currentX, rulerStartY + rulerHeight - tickHeight, currentX, rulerStartY + rulerHeight);

        // Label the size (centered under the tick)
        doc.setFontSize(8);
        doc.text(size.us.toString(), currentX, rulerStartY + rulerHeight + textOffset, null, null, 'center');

        currentX += spacing;
      }

      // Draw a marked slot at the left end
      const slotX = rulerStartX; 
      const slotY = rulerStartY + rulerHeight / 2; 

      doc.setLineWidth(2); 
      doc.setDrawColor(255, 0, 0); 
      doc.line(slotX, rulerStartY, slotX, rulerStartY + rulerHeight); 

      // Draw dashed lines around the slot area
      this.drawDashedLine(doc, slotX - 3, rulerStartY, slotX - 3, rulerStartY + rulerHeight);
      this.drawDashedLine(doc, slotX + 3, rulerStartY, slotX + 3, rulerStartY + rulerHeight);

      // Label the slot area
      doc.setFontSize(10);
      doc.text("Insert here", slotX + 10, slotY); 

      // Footer with website link
      doc.setFontSize(10);
      doc.text("www.RingSize.net", 105, 290, null, null, 'center');

      // Save the PDF
      doc.save("ring-sizer-paper.pdf");
    },

    drawDashedLine(doc, x1, y1, x2, y2) {
      const dashLength = 4; 
      const gapLength = 2;
      const dx = x2 - x1;
      const dy = y2 - y1;
      const lineLength = Math.sqrt(dx * dx + dy * dy);
      const numberOfDashes = Math.floor(lineLength / (dashLength + gapLength));

      const angle = Math.atan2(dy, dx); 

      for (let i = 0; i < numberOfDashes; i++) {
        const dashStartX = x1 + (dashLength + gapLength) * i * Math.cos(angle);
        const dashStartY = y1 + (dashLength + gapLength) * i * Math.sin(angle);
        const dashEndX = dashStartX + dashLength * Math.cos(angle);
        const dashEndY = dashStartY + dashLength * Math.sin(angle);

        doc.line(dashStartX, dashStartY, dashEndX, dashEndY);
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  text-align: center;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
}

button {
  margin-top: 20px;
}
</style>