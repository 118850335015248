<template>
  <div>
    <h1>Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
