<template>
  <div class="container mt-4">
    <div class="row justify-content-center">
      <div class="col-md-6">  <!-- Adjusted column size for better responsiveness -->
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-center mb-4">Ring Size Measurement</h1>

            <!-- Step-by-Step Guide -->
            <div v-if="!cameraActive && !measurementResults">
              <h2 class="card-subtitle mb-3">Let's get started!</h2>
              <ol class="list-decimal">
                <li>Click "Start Camera".</li>
                <li>Place a credit card on a flat surface.</li>
                <li>Align your finger above the card, within the red frame.</li>
                <li>Hold still for a moment while the measurement is taken.</li>
              </ol>
            </div>

            <!-- Camera Preview Section -->
            <div v-if="cameraActive" class="ratio ratio-16x9 overflow-hidden mb-3">
              <video ref="videoElement" class="object-fit-cover" autoplay playsinline></video>
              <div class="position-absolute top-50 start-50 translate-middle pointer-events-none">
                <div class="border border-danger rounded transition-opacity duration-300" :class="{ 'opacity-50': processingImage }" :style="creditCardFrameStyle">
                  <div v-if="processingImage" class="d-flex justify-content-center align-items-center h-100">
                    <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                    <span class="text-danger small">Measuring...</span>
                  </div>
                </div>
              </div>
            </div>


            <!-- Camera Controls -->
            <div v-if="!measurementResults" class="text-center">
              <button @click="toggleCamera" class="btn" :class="{ 'btn-primary': !cameraActive, 'btn-danger': cameraActive }">
                {{ cameraActive ? 'Stop Camera' : 'Start Camera' }}
              </button>
              <div v-if="cameraError" class="text-danger mt-2">{{ cameraError }}</div>
            </div>

            <!-- Measurement Results Section -->
            <div v-if="measurementResults" class="alert alert-success mt-3">
              <h3 class="alert-heading mb-3">Ring Measurement Results</h3>
              <div class="row">
                <div class="col-6">
                  <p class="fw-bold">Finger Width:</p>
                  <p>{{ measurementResults.fingerWidth.toFixed(2) }} mm</p>
                </div>
                <div class="col-6">
                  <p class="fw-bold">Ring Size:</p>
                  <p>US: {{ measurementResults.ringSize.us }} | UK: {{ measurementResults.ringSize.uk }} | Diameter: {{ measurementResults.ringSize.diameter }}mm</p>
                </div>
              </div>
              <div class="text-center mt-3">
                <button @click="resetMeasurement" class="btn btn-primary">Measure Again</button>
              </div>
            </div>
          </div>
        </div>


        <!-- Measurement Tips (Always Visible) -->
         <div class="card mt-4">
          <div class="card-body">
            <h2 class="card-subtitle mb-3">Measurement Tips</h2>
            <ul class="list-disc">
               <li>Ensure good lighting and a plain background.</li>
                <li>Place a standard credit card in the frame for scale.</li>
                <li>Keep your finger still during measurement.</li>
                <li>Measure at room temperature when fingers are not swollen.</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'; // Import watch
import * as cv from 'opencv.js';
import { sizeChart } from '../constants/sizeChart.js'; // Make sure to create this file

// Configuration Constants
const CREDIT_CARD_WIDTH_MM = 85.6;
const CREDIT_CARD_HEIGHT_MM = 53.98;
const MIN_FINGER_WIDTH_MM = 13.0;
const MAX_FINGER_WIDTH_MM = 25.0;


// Reactive State
const videoElement = ref(null);
const cameraContainer = ref(null);
const cameraStream = ref(null);
const cameraActive = ref(false);
const cameraError = ref(null);
const measurementResults = ref(null);
const processingImage = ref(false);


// Camera Frame Style
const creditCardFrameStyle = computed(() => {
  if (!cameraContainer.value) return {};
  const containerWidth = cameraContainer.value.clientWidth;
  const scale = containerWidth / CREDIT_CARD_WIDTH_MM;
  return {
    width: `${CREDIT_CARD_WIDTH_MM * scale}px`,
    height: `${CREDIT_CARD_HEIGHT_MM * scale}px`,
    border: '2px solid red',
  };
});

// Toggle Camera
const toggleCamera = async () => {
  if (cameraActive.value) {
    stopCamera();
  } else {
    await startCamera();
  }
};


const startCamera = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        facingMode: 'environment',
      },
    });

    videoElement.value.srcObject = stream;
    cameraStream.value = stream;
    cameraActive.value = true;
    cameraError.value = null;

    videoElement.value.onloadedmetadata = () => {
      videoElement.value.play();
      initializeImageProcessing();
    };
  } catch (error) {
    cameraError.value =
      error.name === 'NotAllowedError'
        ? 'Camera access denied. Please enable camera permissions.'
        : 'Failed to access camera. Please check your device.';
    console.error('Camera error:', error);
  }
};


const stopCamera = () => {
  if (cameraStream.value) {
    const tracks = cameraStream.value.getTracks();
    tracks.forEach((track) => track.stop());
    videoElement.value.srcObject = null;
    cameraActive.value = false;
    // measurementResults.value = null; // Don't reset results here if you want to keep them after stopping the camera
  }
};


const initializeImageProcessing = () => {
  const video = videoElement.value;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const processFrame = () => {
    if (!cameraActive.value) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const src = cv.matFromImageData(imageData);

    try {
       processingImage.value = true; // Set loading to true
      const fingerWidth = detectFingerWidth(src); // Pass 'src' to detectFingerWidth
      if (fingerWidth) {
        const ringSize = calculateRingSize(fingerWidth);
        measurementResults.value = {
          fingerWidth,
          ringSize,
        };
      }
    } catch (error) {
      console.error('Measurement error:', error);
    } finally {
      src.delete();
       processingImage.value = false;  // Set loading to false, even if there's an error
    }

    requestAnimationFrame(processFrame);
  };

  processFrame();
};

// Detect Finger Width
const detectFingerWidth = (src) => {
  const gray = new cv.Mat();
  const blurred = new cv.Mat();
  const edges = new cv.Mat();

  cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);
  cv.GaussianBlur(gray, blurred, new cv.Size(5, 5), 0);
  cv.Canny(blurred, edges, 50, 150);

  const contours = new cv.MatVector();
  const hierarchy = new cv.Mat();
  cv.findContours(edges, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

  let fingerWidth = null;
  for (let i = 0; i < contours.size(); i++) {
    const contour = contours.get(i);
    const area = cv.contourArea(contour);
    
    if (area > 1000) {  // Filter out small noise
      const boundingBox = cv.boundingRect(contour);
      const widthMM = (boundingBox.width / src.cols) * CREDIT_CARD_WIDTH_MM;

      if (widthMM >= MIN_FINGER_WIDTH_MM && widthMM <= MAX_FINGER_WIDTH_MM) {
        fingerWidth = widthMM;
        break;
      }
    }
  }

  // Clean up
  gray.delete();
  blurred.delete();
  edges.delete();
  contours.delete();
  hierarchy.delete();

  return fingerWidth;
};

// Calculate Ring Size
const calculateRingSize = (fingerWidth) => {
  const closestSize = sizeChart.reduce((prev, curr) => {
    return Math.abs(curr.diameter - fingerWidth) < Math.abs(prev.diameter - fingerWidth) ? curr : prev;
  });
  return closestSize;
};

const resetMeasurement = () => {
  measurementResults.value = null;
};


onMounted(() => { // Use onMounted
  // Watch cameraActive to start the camera only when it becomes true
    watch(cameraActive, (newVal) => { 
        if(newVal){
            startCamera();
        }
    });
});
</script>

<style scoped>
.btn {
  @apply py-2 px-4 rounded-lg text-white font-bold transition-colors duration-300;
}
</style>