<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h1 class="text-center mb-4">About Ring Size Calculator</h1>
        <div class="card shadow-sm">
          <div class="card-body">
            <h2 class="h4 mb-3">Your Trusted Ring Size Resource</h2>
            <p class="lead">Welcome to RingSize.net, your go-to 
              destination for accurate ring sizing measurements.</p>
            
            <div class="mb-4">
              <h3 class="h5">Our Mission</h3>
              <p>At RingSize.net, we're committed to helping you find your 
                perfect ring size with precision and ease. Our mission is to eliminate the 
                guesswork from ring sizing, ensuring that every piece of jewelry you 
                purchase fits perfectly.</p>
            </div>

            <div class="mb-4">
              <h3 class="h5">Why Choose Us?</h3>
              <ul class="list-unstyled">
                <li class="mb-2">✓ Accurate measurements using advanced 
                  algorithms</li>
                <li class="mb-2">✓ Easy-to-use digital ring sizer</li>
                <li class="mb-2">✓ Comprehensive size guides for all 
                  standards</li>
                <li class="mb-2">✓ Expert tips and advice</li>
              </ul>
            </div>

            <div class="mb-4">
              <h3 class="h5">Our Expertise</h3>
              <p>With years of experience in jewelry and digital 
                measurements, our team has developed the most reliable online ring sizing 
                tool. We combine traditional jewelry expertise with modern technology to 
                provide you with accurate results.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>