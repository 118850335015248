<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h1 class="text-center mb-4">Privacy Policy</h1>
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="mb-4">
              <h2 class="h4">Information We Collect</h2>
              <p>We collect information that you provide directly to us 
when using our ring size calculator service:</p>
              <ul class="list-unstyled">
                <li class="mb-2">• Personal measurements and 
preferences</li>
                <li class="mb-2">• Contact information when provided</li>
                <li class="mb-2">• Usage data and cookies</li>
              </ul>
            </div>

            <div class="mb-4">
              <h2 class="h4">How We Use Your Information</h2>
              <p>The information we collect is used to:</p>
              <ul class="list-unstyled">
                <li class="mb-2">• Provide accurate ring size 
calculations</li>
                <li class="mb-2">• Improve our services</li>
                <li class="mb-2">• Communicate with you about our 
services</li>
                <li class="mb-2">• Ensure website security</li>
              </ul>
            </div>

            <div class="mb-4">
              <h2 class="h4">Data Protection</h2>
              <p>We implement appropriate data collection, storage, and 
processing practices and security measures to protect against unauthorized 
access, alteration, disclosure, or destruction of your personal 
information.</p>
            </div>

            <div class="mb-4">
              <h2 class="h4">Your Rights</h2>
              <p>You have the right to:</p>
              <ul class="list-unstyled">
                <li class="mb-2">• Access your personal data</li>
                <li class="mb-2">• Correct your personal data</li>
                <li class="mb-2">• Request deletion of your personal 
data</li>
                <li class="mb-2">• Object to our use of your data</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
