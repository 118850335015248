<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h1 class="text-center mb-4">Terms & Conditions</h1>
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="mb-4">
              <h2 class="h4">1. Acceptance of Terms</h2>
              <p>By accessing and using RingSize.net, you accept and agree 
                to be bound by the terms and provision of this agreement.</p>
            </div>

            <div class="mb-4">
              <h2 class="h4">2. Use License</h2>
              <p>Permission is granted to temporarily use this website for 
                personal, non-commercial transitory viewing only.</p>
              <ul class="list-unstyled">
                <li class="mb-2">• You must not modify or copy the 
                  materials</li>
                <li class="mb-2">• You must not use the materials for any 
                  commercial purpose</li>
                <li class="mb-2">• You must not attempt to decompile or 
                  reverse engineer any software</li>
              </ul>
            </div>

            <div class="mb-4">
              <h2 class="h4">3. Disclaimer</h2>
              <p>The materials on RingSize.net are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
            </div>

            <div class="mb-4">
              <h2 class="h4">4. Limitations</h2>
              <p>In no event shall RingSize.net or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on our website.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>