<template>
  <div id="app">
    <AppHeader />
    <SpeedInsights />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
import { SpeedInsights } from '@vercel/speed-insights/vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    SpeedInsights,
  },
};
</script>

<style scoped>
#app {
  min-height: 100vh;
  padding-bottom: 80px; /* To make space for the fixed footer */
}
</style>