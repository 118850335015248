<!-- RingMethod.vue -->
<template>
  <div class="ring-sizer-container">
    <!-- Tab Navigation -->
    <div class="tab-navigation">
      <button 
        v-for="tab in tabs" 
        :key="tab.value"
        class="tab-button"
        :class="{ 'active': currentTab === tab.value }"
        @click="currentTab = tab.value"
      >
        {{ tab.label }}
      </button>
    </div>

    <!-- Circle Method -->
    <div v-if="currentTab === 'circle'" class="measurement-section">
      <!-- Calibration Section -->
      <section class="calibration-section">
        <h2 class="section-title">Step 1: Screen Calibration</h2>
        <div class="calibration-container">
          <div class="instruction-text">
            <p>Place a credit card on your screen and adjust the rectangle to match its width (85.60mm)</p>
          </div>
          
          <div class="credit-card-display" ref="cardDisplay">
            <div 
              class="credit-card-rectangle" 
              :style="creditCardStyle"
              ref="cardRectangle"
            ></div>
          </div>

          <div class="calibration-controls">
            <div class="slider-with-buttons">
              <button 
                class="adjust-button" 
                @click="adjustCreditCard(-0.1)"
                @keydown.left="adjustCreditCard(-0.1)"
                @keydown.right="adjustCreditCard(0.1)"
                ref="decreaseButton"
                tabindex="0"
                title="Decrease (or use left arrow key)"
              >-</button>
              <input
                type="range"
                v-model.number="creditCardSize"
                :min="minCardSize"
                :max="maxCardSize"
                step="0.1"
                class="slider"
                @keydown.left="adjustCreditCard(-0.1)"
                @keydown.right="adjustCreditCard(0.1)"
              />
              <button 
                class="adjust-button" 
                @click="adjustCreditCard(0.1)"
                @keydown.left="adjustCreditCard(-0.1)"
                @keydown.right="adjustCreditCard(0.1)"
                ref="increaseButton"
                tabindex="0"
                title="Increase (or use right arrow key)"
              >+</button>
            </div>
            <div class="size-display">
              <span>Current width: {{ creditCardSize.toFixed(2) }}mm</span>
            </div>
          </div>
        </div>
      </section>

      <!-- Ring Measurement Section -->
      <section class="ring-measurement">
        <h2 class="section-title">Step 2: Ring Size Measurement</h2>
        <div class="measurement-container">
          <div class="instruction-text">
            <p>Place your ring over the circle and adjust until it matches perfectly</p>
          </div>

          <div class="circle-display">
            <div 
              class="measurement-circle" 
              :style="ringCircleStyle"
              ref="measurementCircle"
            ></div>
          </div>

          <div class="measurement-controls">
            <div class="slider-with-buttons">
              <button 
                class="adjust-button" 
                @click="adjustCircle(-0.1)"
                title="Decrease (or use left arrow key)"
              >-</button>
              <input
                type="range"
                v-model.number="circleDiameter"
                :min="getAdjustedMinDiameter"
                :max="getAdjustedMaxDiameter"
                step="0.1"
                class="slider"
                @keydown.left="adjustCircle(-0.1)"
                @keydown.right="adjustCircle(0.1)"
              />
              <button 
                class="adjust-button" 
                @click="adjustCircle(0.1)"
                title="Increase (or use right arrow key)"
              >+</button>
            </div>
            <div class="size-display">
              <span>Diameter: {{ getCurrentDiameter() }}mm</span>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Manual Input Method -->
    <div v-else class="manual-input-section">
      <h2 class="section-title">Manual Measurement Input</h2>
      <div class="input-container">
        <label for="manual-diameter">Ring Inner Diameter (mm):</label>
        <input
          type="number"
          id="manual-diameter"
          v-model.number="manualDiameter"
          step="0.1"
          min="0"
          class="manual-input"
        />
      </div>
    </div>

    <!-- Results Display -->
    <div v-if="showResults" class="results-section">
      <h3 class="results-title">Ring Sizes:</h3>
      <div class="size-results">
        <div 
          v-for="(size, system) in currentSizes" 
          :key="system" 
          class="size-item"
        >
          <span class="system-label">{{ system }}:</span>
          <span class="size-value">{{ size }}</span>
        </div>
      </div>

      <div v-if="!hasMatchedSize" class="warning-message">
        <p>Note: Your measurement is outside standard size ranges. Please consult a jeweler for precise sizing.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { sizeChart } from '../constants/sizeChart.js'
export default {
  name: 'RingSizer',
  
  data() {
    return {
      creditCardSize: 85.60,
      creditCardDisplayWidth: 320,
      minCardSize: 80,
      maxCardSize: 160,
      circleDiameter: 17.3,
      manualDiameter: 17.3,
      currentTab: 'circle',
      minDiameter: 14.0,
      maxDiameter: 24.0,
      baseCardWidth: 85.60, // Standard credit card width
      tabs: [
        { label: 'Circle Method', value: 'circle' },
        { label: 'Manual Input', value: 'input' }
      ],
      sizeChart
    }
  },

  computed: {
    isCalibrated() {
      return Math.abs(this.creditCardSize - this.baseCardWidth) <= 0.1;
    },

    mmToPixelRatio() {
      // Inverse ratio for circle size to respond inversely to credit card adjustment
      return this.baseCardWidth / this.creditCardSize * (this.creditCardDisplayWidth / this.baseCardWidth);
    },

    creditCardStyle() {
      const width = this.creditCardSize * (this.creditCardDisplayWidth / this.baseCardWidth);
      const height = width * (53.98 / 85.60);

      return {
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: '#e0e0e0',
        border: '1px solid #ccc'
      };
    },

    ringCircleStyle() {
      // Apply inverse ratio for circle size
      const diameter = this.circleDiameter * this.mmToPixelRatio;
      
      return {
        width: `${diameter}px`,
        height: `${diameter}px`,
        borderRadius: '50%',
        border: '2px solid #666',
        backgroundColor: 'transparent'
      };
    },

    showResults() {
      return this.currentTab === 'circle' ? true : this.manualDiameter > 0;
    },

    hasMatchedSize() {
      const diameter = this.getCurrentDiameter();
      const tolerance = 0.2;
      
      return this.sizeChart.some(size => 
        Math.abs(size.diameter - diameter) <= tolerance
      );
    },

    currentSizes() {
      const diameter = this.getCurrentDiameter();
      
      const size = this.sizeChart.reduce((prev, curr) => {
        return Math.abs(curr.diameter - diameter) < Math.abs(prev.diameter - diameter) 
          ? curr 
          : prev;
      });
      
      return {
        'US': size.us.toFixed(1),
        'UK': size.uk,
        'France': size.france,
        'Germany': size.germany,
        'Italy': size.italy,
        'Spain': size.spain,
        'Japan': size.japan    
      };
    },

    getAdjustedMinDiameter() {
      // Adjust minimum diameter based on credit card calibration
      return this.minDiameter * (this.creditCardSize / this.baseCardWidth);
    },

    getAdjustedMaxDiameter() {
      // Adjust maximum diameter based on credit card calibration
      return this.maxDiameter * (this.creditCardSize / this.baseCardWidth);
    }
  },

  methods: {
    getCurrentDiameter() {
      if (this.currentTab === 'circle') {
        // Apply inverse relationship when calculating actual diameter
        const adjustedDiameter = this.circleDiameter * (this.baseCardWidth / this.creditCardSize);
        return parseFloat(adjustedDiameter.toFixed(2));
      }
      return parseFloat((this.manualDiameter).toFixed(2));
    },

    updateCardDisplaySize() {
      const cardElement = this.$refs.cardRectangle;
      if (cardElement) {
        this.creditCardDisplayWidth = cardElement.offsetWidth;
      }
    },

    resetMeasurements() {
      this.circleDiameter = 17.3;
      this.manualDiameter = 17.3;
    },

    adjustCreditCard(delta) {
      const newSize = this.creditCardSize + delta;
      if (newSize >= this.minCardSize && newSize <= this.maxCardSize) {
        this.creditCardSize = newSize;
      }
    },

    adjustCircle(delta) {
      const newSize = this.circleDiameter + delta;
      if (newSize >= this.getAdjustedMinDiameter && newSize <= this.getAdjustedMaxDiameter) {
        this.circleDiameter = newSize;
      }
    },

    setupKeyboardListeners() {
      const decreaseButton = this.$refs.decreaseButton;
      const increaseButton = this.$refs.increaseButton;

      if (decreaseButton && increaseButton) {
        decreaseButton.addEventListener('keydown', (e) => {
          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            e.preventDefault();
            this.adjustCreditCard(e.key === 'ArrowLeft' ? -0.1 : 0.1);
          }
        });

        increaseButton.addEventListener('keydown', (e) => {
          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            e.preventDefault();
            this.adjustCreditCard(e.key === 'ArrowLeft' ? -0.1 : 0.1);
          }
        });
      }
    }
  },

  mounted() {
    this.updateCardDisplaySize();
    this.setupKeyboardListeners();
    window.addEventListener('resize', this.updateCardDisplaySize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateCardDisplaySize);
  },

  watch: {
    currentTab() {
      this.resetMeasurements();
    }
  }
}
</script>

<style scoped>
.ring-sizer-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.tab-navigation {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  border-radius: 4px;
}

.tab-button.active {
  background: #007bff;
  color: white;
  border-color: #0056b3;
}

.section-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
}

.calibration-container,
.measurement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.instruction-text {
  text-align: center;
  margin-bottom: 15px;
  color: #666;
}

.credit-card-display,
.circle-display {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  background: #f9f9f9;
  width: 100%;
  display: flex;
  justify-content: center;
}

.slider-with-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin: 10px 0;
}

.adjust-button {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  user-select: none;
  outline: none;
}

.adjust-button:hover {
  background: #f0f0f0;
}

.adjust-button:active {
  background: #e0e0e0;
}

.adjust-button:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.slider {
  flex: 1;
  width: 100%;
  min-width: 200px;
}

.size-display {
  text-align: center;
  font-size: 0.9em;
  color: #666;
}

.manual-input-section {
  margin: 20px 0;
}

.manual-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 120px;
}

.results-section {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f5f5f5;
}

.size-results {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.size-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.warning-message {
  margin-top: 15px;
  padding: 10px;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
}

@media (max-width: 600px) {
  .ring-sizer-container {
    padding: 10px;
  }
  
  .size-results {
    grid-template-columns: 1fr;
  }
}
</style>