import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import vueGtag from 'vue-gtag';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.css';

const app = createApp(App);
const head = createHead();

app.use(vueGtag, { config: { id: 'G-Q8VR2R7VTG' } })
   .use(head)
   .use(router)
   .mount('#app');