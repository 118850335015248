<!-- StringMethod.vue -->
<template>
  <div class="measurement-wrapper">
    <!-- Tab Navigation -->
    <div class="tab-container">
      <button 
        v-for="tab in tabs" 
        :key="tab.value"
        class="tab-button" 
        :class="{ 'tab-button-active': currentTab === tab.value }" 
        @click="currentTab = tab.value"
      >
        {{ tab.label }}
      </button>
    </div>

    <!-- Slider Method -->
    <div v-if="currentTab === 'slider'" class="method-container">
      <!-- Card Calibration Section -->
      <section class="calibration-section">
        <h2 class="section-title">Step 1: Calibrate Screen Size</h2>
        <div class="credit-card-container">
          <div class="credit-card-placeholder">
            <div class="credit-card-info">
              <p>Place your credit card flat on the screen and adjust until the width matches exactly (85.60mm)</p>
            </div>
            <div class="credit-card" :style="creditCardStyle"></div>
          </div>
          <div class="slider-controls">
            <button 
              class="adjust-button"
              @click="adjustCreditCard('decrease')"
              @keydown.left.prevent="adjustCreditCard('decrease')"
            >
              -
            </button>
            <div class="slider-container">
              <input
                type="range"
                v-model.number="creditCardSize"
                :min="minCardSize"
                :max="maxCardSize"
                :step="stepSize"
                class="slider"
                @keydown.left.prevent="adjustCreditCard('decrease')"
                @keydown.right.prevent="adjustCreditCard('increase')"
              />
              <div class="slider-labels">
                <span class="min-label">{{ minCardSize }}mm</span>
                <span class="current-value">{{ creditCardSize.toFixed(2) }}mm</span>
                <span class="max-label">{{ maxCardSize }}mm</span>
              </div>
            </div>
            <button 
              class="adjust-button"
              @click="adjustCreditCard('increase')"
              @keydown.right.prevent="adjustCreditCard('increase')"
            >
              +
            </button>
          </div>
        </div>
      </section>

      <!-- Measurement Section -->
      <section v-if="isCalibrated" class="measurement-section">
        <h2 class="section-title">Step 2: Measure Your Ring Size</h2>
        <div class="measurement-line" :style="measurementLineStyle"></div>
        <div class="slider-controls">
          <button 
            class="adjust-button"
            @click="adjustStringLength('decrease')"
            @keydown.left.prevent="adjustStringLength('decrease')"
          >
            -
          </button>
          <div class="slider-container">
            <input 
              type="range" 
              v-model.number="paperStringLength" 
              :max="maxMeasurementLength"
              :min="0"
              :step="stepSize"
              class="slider"
              @keydown.left.prevent="adjustStringLength('decrease')"
              @keydown.right.prevent="adjustStringLength('increase')"
            />
            <p class="measurement-value">
              String Length: {{ actualStringLength.toFixed(2) }} mm
            </p>
          </div>
          <button 
            class="adjust-button"
            @click="adjustStringLength('increase')"
            @keydown.right.prevent="adjustStringLength('increase')"
          >
            +
          </button>
        </div>
      </section>
    </div>

    <!-- Manual Input Method -->
    <div v-else class="measurement-section">
      <h2 class="section-title">Enter Your Measurement</h2>
      <div class="manual-input-container">
        <label for="manual-length">Enter string length (mm):</label>
        <div class="input-with-controls">
          <button 
            class="adjust-button"
            @click="adjustManualLength('decrease')"
          >
            -
          </button>
          <input 
            type="number" 
            id="manual-length" 
            v-model.number="rulerStringLength" 
            :step="stepSize"
            class="manual-input"
            @keydown.up.prevent="adjustManualLength('increase')"
            @keydown.down.prevent="adjustManualLength('decrease')"
          />
          <button 
            class="adjust-button"
            @click="adjustManualLength('increase')"
          >
            +
          </button>
        </div>
      </div>
    </div>

    <!-- Results Section -->
    <div v-if="showResults" class="results-section">
      <div class="measurement-results">
        <p class="result-item">Diameter: {{ currentDiameter }} mm</p>
        <p v-if="!hasMatchedSize" class="size-warning">
          Note: Your measurement is outside our standard size range. Please consult a professional jeweler.
        </p>
      </div>

      <div class="size-conversion">
        <h3 class="conversion-title">Ring Sizes:</h3>
        <div class="size-grid">
          <div v-for="(size, system) in currentSizes" :key="system" class="size-item">
            <span class="size-label">{{ system }}:</span>
            <span class="size-value">{{ size }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CREDIT_CARD_WIDTH = 85.60;  // Standard credit card width in mm
const CREDIT_CARD_HEIGHT = 53.98; // Standard credit card height in mm
const PX_PER_MM = 3.779527;      // Pixels per mm at 96 DPI

import { sizeChart } from '../constants/sizeChart.js'
export default {
  name: 'RingSizer',
  
  data() {
    return {
      creditCardSize: CREDIT_CARD_WIDTH,
      minCardSize: 80,      // Minimum card size in mm
      maxCardSize: 160,     // Maximum card size in mm
      stepSize: 0.1,        // Step size for all controls
      paperStringLength: 54.35,
      rulerStringLength: 54.35,
      currentTab: 'slider',
      tabs: [
        { label: 'Slider Method', value: 'slider' },
        { label: 'Manual Input', value: 'input' }
      ]
    }
  },

  computed: {
    isCalibrated() {
      return this.creditCardSize >= CREDIT_CARD_WIDTH;
    },

    creditCardStyle() {
      const width = Math.max(320, this.creditCardSize * PX_PER_MM);
      const height = width * (CREDIT_CARD_HEIGHT / CREDIT_CARD_WIDTH);
      
      return {
        width: `${width}px`,
        height: `${height}px`
      };
    },

    measurementLineStyle() {
      const width = (this.paperStringLength / this.scalingFactor) * PX_PER_MM;
      return {
        width: `${width}px`
      };
    },

    scalingFactor() {
      return this.creditCardSize / CREDIT_CARD_WIDTH;
    },

    maxMeasurementLength() {
      return 100 * this.scalingFactor; // Adjusted for scaling
    },

    actualStringLength() {
      return this.currentTab === 'slider' 
        ? this.paperStringLength / this.scalingFactor
        : this.rulerStringLength;
    },

    showResults() {
      if (this.currentTab === 'slider') {
        return this.isCalibrated && this.paperStringLength > 0;
      }
      return this.rulerStringLength > 0;
    },

    currentDiameter() {
      return (this.actualStringLength / Math.PI).toFixed(2);
    },

    hasMatchedSize() {
      const diameter = parseFloat(this.currentDiameter);
      return sizeChart.some(size => 
        size.diameter >= diameter - 0.2 && size.diameter <= diameter + 0.2
      );
    },

    currentSizes() {
      const diameter = parseFloat(this.currentDiameter);
      const size = sizeChart.find(size => size.diameter >= diameter - 0.2) || 
                  (diameter < sizeChart[0].diameter ? sizeChart[0] : sizeChart[sizeChart.length - 1]);
      
      return {
        'US': size.us.toFixed(1),
        'UK': size.uk,
        'France': size.france,
        'Germany': size.germany,
        'Italy': size.italy,
        'Spain': size.spain,
        'Japan': size.japan
      };
    }
  },

  methods: {
    adjustCreditCard(direction) {
      if (direction === 'increase') {
        this.creditCardSize = Math.min(this.maxCardSize, this.creditCardSize + this.stepSize);
      } else {
        this.creditCardSize = Math.max(this.minCardSize, this.creditCardSize - this.stepSize);
      }
    },

    adjustStringLength(direction) {
      if (direction === 'increase') {
        this.paperStringLength = Math.min(this.maxMeasurementLength, this.paperStringLength + this.stepSize);
      } else {
        this.paperStringLength = Math.max(0, this.paperStringLength - this.stepSize);
      }
    },

    adjustManualLength(direction) {
      if (direction === 'increase') {
        this.rulerStringLength = Math.min(100, this.rulerStringLength + this.stepSize);
      } else {
        this.rulerStringLength = Math.max(0, this.rulerStringLength - this.stepSize);
      }
    }
  },

  watch: {
    creditCardSize() {
      this.paperStringLength = Math.min(this.maxMeasurementLength, this.paperStringLength);
    },

    currentTab() {
      if (this.currentTab === 'slider') {
        this.paperStringLength = 54.35;
      } else {
        this.rulerStringLength = 54.35;
      }
    }
  }
}
</script>

<style scoped>
.measurement-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.tab-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f5f5f5;
  cursor: pointer;
}

.tab-button-active {
  background: #007bff;
  color: white;
  border-color: #0056b3;
}

.method-container {
  margin-bottom: 30px;
}

.section-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.credit-card-container {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.credit-card {
  background: #e9ecef;
  border-radius: 8px;
  margin: 20px auto;
}

.slider-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.adjust-button {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-container {
  flex: 1;
}

.slider {
  width: 100%;
  margin: 10px 0;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 0.875rem;
  color: #666;
}

.measurement-line {
  height: 2px;
  background: black;
  margin: 20px auto;
  max-width: 100%;
}

.measurement-section {
  margin-top: 30px;
}

.measurement-value {
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
}

.manual-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
}

.input-with-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.manual-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
}

.results-section {
  margin-top: 30px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.measurement-results {
  margin-bottom: 20px;
}

.result-item {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.size-warning {
  color: #dc3545;
  font-style: italic;
  margin-top: 10px;
}

.conversion-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.size-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
}

.size-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.size-label {
  font-weight: bold;
}

.credit-card-info {
  text-align: center;
  margin-bottom: 10px;
  color: #666;
}

.credit-card-placeholder {
  margin: 20px 0;
}
</style>