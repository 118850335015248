<template>
  <div class="home-container">
    <header class="header">
      <h1 class="title">Ring Size Calculator</h1>
      <p class="subtitle">Find your perfect ring size with our easy-to-use tools</p>
    </header>

    <!-- Quick Start Guide -->
    <section class="quick-start">
      <h2>Get Started</h2>
      <div class="method-grid">
        <router-link v-for="method in methods" 
          :key="method.path" 
          :to="method.path" 
          class="method-card">
          <component :is="method.icon" class="method-icon" />
          <h3>{{ method.title }}</h3>
          <p>{{ method.description }}</p>
        </router-link>
      </div>
    </section>

    <!-- Tips Section -->
    <section class="tips-section">
      <h2>Pro Tips for Accurate Measurement</h2>
      <div class="tips-grid">
        <div v-for="(tip, index) in tips" :key="index" class="tip-card">
          <span class="tip-number">{{ index + 1 }}</span>
          <p>{{ tip }}</p>
        </div>
      </div>
    </section>

    <!-- Expandable FAQs -->
    <section class="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div class="faq-grid">
        <div v-for="(faq, index) in faqs" 
          :key="index" 
          class="faq-item"
          :class="{ 'active': faq.isOpen }">
          <button @click="toggleFAQ(index)" 
            class="faq-question"
            :aria-expanded="faq.isOpen">
            <span>{{ faq.question }}</span>
            <span class="faq-icon" aria-hidden="true"></span>
          </button>
          <transition name="fade">
            <div v-show="faq.isOpen" class="faq-answer">
              <p>{{ faq.answer }}</p>
            </div>
          </transition>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { CursorArrowRaysIcon } from '@heroicons/vue/24/outline';
import { CircleStackIcon } from '@heroicons/vue/24/outline';
import { ArrowsPointingOutIcon } from '@heroicons/vue/24/outline';
import { TableCellsIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'AppHome',
  components: {
    CursorArrowRaysIcon,
    CircleStackIcon,
    ArrowsPointingOutIcon,
    TableCellsIcon
  },
  data() {
    return {
      methods: [
        {
          path: '/string-method',
          title: 'String Method',
          description: 'Use a string to measure your finger circumference',
          icon: 'CursorArrowRaysIcon'
        },
        {
          path: '/ring-method',
          title: 'Ring Method',
          description: 'Measure using a ring that fits perfectly',
          icon: 'CircleStackIcon'
        },
        {
          path: '/size-converter',
          title: 'Size Converter',
          description: 'Convert between different sizing systems',
          icon: 'ArrowsPointingOutIcon'
        },
        {
          path: '/size-chart',
          title: 'Size Chart',
          description: 'View comprehensive size charts',
          icon: 'TableCellsIcon'
        }
      ],
      tips: [
        'Measure at the end of the day when your fingers are at their largest',
        'Measure 3-4 times to ensure accuracy',
        'Consider seasonal changes in finger size',
        'Account for knuckle width when measuring',
        'Avoid measuring when hands are cold or hot'
      ],
      faqs: [
        {
          question: 'How do I measure my ring size at home?',
          answer: 'You can use our String Method by wrapping a piece of string or paper strip around your finger, marking where it overlaps, and measuring the length. Alternatively, use our Ring Method with a ring that fits perfectly.',
          isOpen: false
        },
        {
          question: 'What affects ring size?',
          answer: 'Temperature, time of day, activities, and seasonal changes can all affect finger size. Fingers tend to be larger in warm weather and smaller in cold weather. They\'re also typically larger at the end of the day.',
          isOpen: false
        },
        {
          question: 'How do international ring sizes compare?',
          answer: 'Ring sizes vary by country. US sizes range from 3-15, UK from F-Z+5, and EU from 44-74. Use our Size Converter to find your size in different systems.',
          isOpen: false
        },
        {
          question: 'What if my knuckle is larger than my finger?',
          answer: 'Choose a size that fits over your knuckle comfortably while not being too loose on your finger. Consider a hinged ring or spiral ring design for better fit.',
          isOpen: false
        },
        {
          question: 'How accurate are online ring sizers?',
          answer: 'Online measurements are generally accurate when instructions are followed carefully. For best results, verify with multiple methods and consider professional sizing for expensive purchases.',
          isOpen: false
        },
        {
          question: 'What\'s the average ring size?',
          answer: 'The average ring size for women is US 6-7 (EU 52-54) and for men is US 9-10 (EU 59-62). However, individual sizes vary significantly based on factors like height, build, and finger shape.',
          isOpen: false
        }
      ]
    }
  },
  methods: {
    toggleFAQ(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    }
  }
})
</script>

<style scoped>
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.title {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #718096;
  font-size: 1.25rem;
}

.method-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.method-card {
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.method-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-color: #4299e1;
}

.method-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: #4299e1;
  margin-bottom: 1rem;
}

.tips-section {
  margin-bottom: 3rem;
}

.tips-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.tip-card {
  background: #f7fafc;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.tip-number {
  background: #4299e1;
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.faq-grid {
  display: grid;
  gap: 1rem;
}

.faq-item {
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  overflow: hidden;
}

.faq-question {
  width: 100%;
  padding: 1rem;
  background: none;
  border: none;
  text-align: left;
  font-weight: 500;
  color: #2d3748;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-icon {
  width: 1rem;
  height: 1rem;
  position: relative;
}

.faq-icon::before,
.faq-icon::after {
  content: '';
  position: absolute;
  background: #4299e1;
  transition: transform 0.3s ease;
}

.faq-icon::before {
  width: 100%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.faq-icon::after {
  width: 2px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.faq-item.active .faq-icon::after {
  transform: translateX(-50%) rotate(90deg);
}

.faq-answer {
  padding: 1rem;
  background: #f7fafc;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

@media (max-width: 768px) {
  .home-container {
    padding: 1rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .method-grid {
    grid-template-columns: 1fr;
  }
}
</style>