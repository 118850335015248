<template>
  <div class="size-converter">
    <h2>Ring Size Converter</h2>
    <div class="converter-form">
      <!-- Select System and Size Inputs -->
      <div class="input-group">
        <label for="fromSystem">From System</label>
        <select id="fromSystem" v-model="fromSystem">
          <option value="" disabled>Select System</option>
          <option v-for="system in systems" :key="system" :value="system">
            {{ system }}
          </option>
        </select>
      </div>

      <div class="input-group">
        <label for="fromSize">From Size</label>
        <select id="fromSize" v-model="fromSize" :disabled="!fromSystem">
          <option value="" disabled>Select Size</option>
          <option v-for="size in getSizes(fromSystem)" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
      </div>

      <!-- Show converted sizes after selecting a size -->
      <div v-if="fromSize" class="output-group">
        <div v-for="system in systems" :key="system" class="converted-size">
          <template v-if="system !== fromSystem">
            <p><strong>{{ system }}:</strong> {{ convertSize(fromSize, fromSystem, system) }}</p>
          </template>
        </div>
      </div>

      <!-- Clear Button -->
      <div v-if="fromSystem && fromSize" class="clear-button">
        <button @click="clearSelections">Clear Selections</button>
      </div>
    </div>
  </div>
</template>

<script>
import { sizeChart } from '../constants/sizeChart'; // Import the size chart data

export default {
  name: 'SizeConverter',
  data() {
    return {
      fromSystem: '',
      fromSize: '',
      systems: ['us', 'uk', 'france', 'germany', 'italy', 'spain', 'japan'], // Available systems
    };
  },
  methods: {
    // Get available sizes for a given system
    getSizes(system) {
      return sizeChart.map(entry => entry[system]);
    },
    // Clear selections
    clearSelections() {
      this.fromSystem = '';
      this.fromSize = '';
    },
    // Convert size using the sizeChart data
    convertSize(fromSize, fromSystem, toSystem) {
      const sizeEntry = sizeChart.find(entry => entry[fromSystem] === fromSize);
      if (!sizeEntry) return null;

      return sizeEntry[toSystem] || null;
    }
  }
};
</script>

<style scoped>
.size-converter {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.converter-form {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

select:disabled {
  background-color: #f0f0f0;
}

.output-group {
  margin-top: 20px;
}

.converted-size p {
  margin: 5px 0;
}

.clear-button {
  margin-top: 20px;
  text-align: center;
}

button {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #e53e3e;
}

button:focus {
  outline: none;
}

@media (max-width: 768px) {
  .size-converter {
    padding: 15px;
  }

  h2 {
    font-size: 1.5rem;
  }

  select {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }
}
</style>