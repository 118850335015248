<template>
  <div class="container-fluid mt-3 mb-3">
    <!-- Main display for screen -->
    <div class="row mb-3">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title h3 text-center mb-2">Ring Size Chart</h1>
            <p class="text-center text-muted mb-3">Download this chart and match your ring to find your size</p>
            <div class="alert alert-info py-2" role="alert">
              <i class="bi bi-info-circle me-2"></i>
              The PDF will be generated at actual size for accurate measurements.
            </div>
            <div class="text-center">
              <button class="btn btn-primary" @click="exportToPdf" :disabled="isExporting">
                <i class="bi bi-file-pdf me-2"></i>
                {{ isExporting ? 'Generating PDF...' : 'Download Size Chart' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ring size content (visible only on screen) -->
    <div ref="pdfContent" class="ring-content">
      <div class="ring-grid">
        <div v-for="size in ringSizes" :key="size.us" class="ring-item">
          <div class="ring-circle" :style="{ width: size.diameter + 'mm', height: size.diameter + 'mm' }">
            <span class="ring-size-text">{{ size.us }}</span>
            <div class="ring-inner-circle"></div>
          </div>
          <div class="size-info">
            <strong>Size {{ size.us }}</strong><br>
            <small>{{ size.diameter }}mm</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import { sizeChart } from '@/constants/sizeChart';

export default {
  name: 'RingSizeChart',
  data() {
    return {
      ringSizes: sizeChart,
      isExporting: false
    };
  },
  methods: {
    async exportToPdf() {
      this.$gtag.event('download', {
        event_category: 'pdf',
        event_label: 'ring circles',
        value: 1
      });
      this.isExporting = true;
      try {
        const pdfContent = this.$refs.pdfContent;

        const tempDiv = document.createElement('div');
        
        // Create title, instructions, and footer in HTML format
        tempDiv.innerHTML = `
          <div class="pdf-header" style="text-align: center; margin-bottom: 4mm;">
            <h1 style="font-size: 20pt; margin: 0; padding: 0;">Ring Size Chart</h1>
            <p class="subtitle" style="font-size: 12pt; font-weight: 300; margin: 2mm 0;">Match your ring to find your ring size</p>
          </div>
          ${pdfContent.innerHTML}
          <div class="instructions" style="margin-top: 6mm; font-size: 12pt; text-align: left; padding-left: 10mm; margin-bottom: 15mm;">
            <h5 style="font-size: 14pt; margin-bottom: 2mm;">Measure an existing ring:</h5>
            <ol>
              <li>Choose a ring that correctly fits the intended finger.</li>
              <li>Lay the ring over each size circle within the chart until you can closely match the inside edge to one of the circles on the guide. This is your ring size!</li>
            </ol>
          </div>
          <div class="pdf-footer" style="text-align: center; position: absolute; bottom: 5mm; width: 100%; font-size: 10pt; padding: 0 5mm;">
            <p>www.RingSize.net</p>
          </div>
        `;
        
        const opt = {
          margin: [5, 5, 10, 5], // top, right, bottom, left
          filename: 'ring-size-chart.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { 
            scale: 2,
            useCORS: true,
            letterRendering: true
          },
          jsPDF: { 
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait'
          }
        };

        await html2pdf().set(opt).from(tempDiv).save();
      } catch (error) {
        console.error('PDF export failed:', error);
      } finally {
        this.isExporting = false;
      }
    }
  }
}
</script>

<style scoped>
/* General Content for the Page */
.ring-content {
  max-width: 210mm; /* A4 width */
  margin: 0 auto;
  padding: 5mm;
  box-sizing: border-box;
  position: relative;
  min-height: 215mm; /* Ensure space for footer and instructions */
}

/* Ring Grid (Visible on screen and in PDF) */
.ring-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6mm;
  margin-bottom: 6mm;
}

.ring-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  break-inside: avoid;
}

.ring-circle {
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}

.ring-size-text {
  position: absolute;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.ring-inner-circle {
  width: 0.5mm;
  height: 0.5mm;
  background-color: #000;
  border-radius: 50%;
}

.size-info {
  font-size: 0.9rem;
  margin-top: 2mm;
  text-align: center;
}

/* PDF-specific styles (shown only in PDF) */
@media print {
  /* Title and Subtitle */
  .pdf-header {
    text-align: center;
    margin-bottom: 4mm;
  }

  .pdf-header h1 {
    font-size: 20pt;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  .pdf-header .subtitle {
    font-size: 12pt;
    margin-top: 2mm;
    font-weight: 300;
  }

  /* Instructions */
  .instructions {
    font-size: 12pt;
    margin-top: 6mm;
    text-align: left;
    padding: 0 10mm;
    margin-bottom: 15mm; /* Ensure space for footer */
  }

  .instructions h5 {
    font-size: 14pt;
    margin-bottom: 2mm;
  }

  .instructions ol {
    padding-left: 20px;
  }

  /* Footer */
  .pdf-footer {
    text-align: center;
    font-size: 10pt;
    color: #444;
    position: absolute;
    bottom: 5mm;
    left: 0;
    right: 0;
    padding: 0 5mm;
  }

  .pdf-footer p {
    margin: 0;
    font-size: 10pt;
    color: #444;
  }

  /* Hide card and other screen-specific UI */
  .card {
    display: none;
  }

  /* Ring Grid Adjustments */
  .ring-grid {
    gap: 8mm;
  }

  .ring-circle {
    width: 15mm;
    height: 15mm;
  }

  .ring-size-text {
    font-size: 0.9rem;
  }
}
</style>