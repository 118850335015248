<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h1 class="text-center mb-4">Frequently Asked Questions</h1>
        <div class="accordion" id="faqAccordion">
          <div v-for="(faq, index) in faqs" :key="index" class="accordion-item">
            <h2 class="accordion-header">
              <button 
                class="accordion-button" 
                :class="{ collapsed: !faq.isOpen }"
                type="button" 
                @click="toggleFaq(index)"
              >
                {{ faq.question }}
              </button>
            </h2>
            <div 
              class="accordion-collapse collapse" 
              :class="{ show: faq.isOpen }"
            >
              <div class="accordion-body">
                {{ faq.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

// FAQ data
const faqs = ref([
        {
          question: 'How do I measure my ring size at home?',
          answer: 'You can use our String Method by wrapping a piece of string or paper strip around your finger, marking where it overlaps, and measuring the length. Alternatively, use our Ring Method with a ring that fits perfectly.',
          isOpen: false
        },
        {
          question: 'What affects ring size?',
          answer: 'Temperature, time of day, activities, and seasonal changes can all affect finger size. Fingers tend to be larger in warm weather and smaller in cold weather. They\'re also typically larger at the end of the day.',
          isOpen: false
        },
        {
          question: 'How do international ring sizes compare?',
          answer: 'Ring sizes vary by country. US sizes range from 3-15, UK from F-Z+5, and EU from 44-74. Use our Size Converter to find your size in different systems.',
          isOpen: false
        },
        {
          question: 'What if my knuckle is larger than my finger?',
          answer: 'Choose a size that fits over your knuckle comfortably while not being too loose on your finger. Consider a hinged ring or spiral ring design for better fit.',
          isOpen: false
        },
        {
          question: 'How accurate are online ring sizers?',
          answer: 'Online measurements are generally accurate when instructions are followed carefully. For best results, verify with multiple methods and consider professional sizing for expensive purchases.',
          isOpen: false
        },
        {
          question: 'What\'s the average ring size?',
          answer: 'The average ring size for women is US 6-7 (EU 52-54) and for men is US 9-10 (EU 59-62). However, individual sizes vary significantly based on factors like height, build, and finger shape.',
          isOpen: false
        }
])

// FAQ toggle function
const toggleFaq = (index) => {
  faqs.value[index].isOpen = !faqs.value[index].isOpen
}
</script>